$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/surprise/';
@import 'styles/helpers/global_variables';

$fontColor: #fff;

.root {
  .inputSelectedCountBadge {
    position: absolute;
    right: 35px;
    top: 8px;
    background-color: $filter-form-badge-bg-color !important;
  }

  // search input
  .input {
    border: 1px solid $filter-form-input-border-color;
    background-color: transparent;
    color: $filter-form-input-text-color;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $filter-form-input-placeholder-color;
      font-size: 0.9rem;
      font-style: italic;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $filter-form-input-placeholder-color;
      font-size: 0.9rem;
      font-style: italic;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $filter-form-input-placeholder-color;
      font-size: 0.9rem;
      font-style: italic;
    }
  }
}
