@mixin font-bold {
  font-family: 'visionblack', $font-family-sans-serif;
}

@mixin jumbo-title {
  font-family: $theme-font-bold;
  font-size: 3rem;
  @include font-bold;

  @include media-breakpoint-up(md) {
    margin-bottom: 1rem;
    font-size: 5rem;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 1.3rem;
    font-size: 7rem;
  }
}

@mixin heading-block-h1 {
  @include font-bold;

  display: inline-block;
  font-size: 2.3rem;
  margin-bottom: 1rem;

  @include media-breakpoint-up(sm) {
    font-size: 3.2rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 4rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 4.5rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 4.8rem;
  }
}

@mixin heading-block-h4 {
  @include font-bold;

  display: inline-block;
  font-size: 1.8rem;
  margin-bottom: 1rem;

  @include media-breakpoint-up(sm) {
    font-size: 2rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 2.3rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 2.5rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 3rem;
  }
}

@mixin heading-block-subtitle {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  @include font-uppercase;
}

// booking wizard
@mixin booking-wizard-item-label {
  @include font-uppercase;
  @include font-bold;
  font-family: $theme-font-regular;
}
// end - booking wizard
