// scss-docs-start page-header-variables
$header-slider-content-padding-bottom: 5.3rem;
// scss-docs-end page-header-variables

// scss-docs-start main-nav
$headerHeight: 93px;
$navHeight: 93px;
// scss-docs-end main-nav

// scss-docs-start filter
$filter-form-bg-color: $primary;
$filter-form-color: $white;
$filter-form-submit-margin: 0 -0.5rem 0 -0.5rem; // lead-form
$filter-form-input-border-color: $gray-400;
$filter-form-input-placeholder-color: $gray-400;
$filter-form-input-placeholder-hover-color: $gray-100;
$filter-form-input-text-color: $white;
$filter-form-input-chevron-color: encodecolor($gray-200);
$filter-form-input-chevron-hover-color: encodecolor($white);
$filter-form-calendar-year-label-bg-color: $gray-600;
$filter-form-calendar-month-hover-bg-color: $gray-600;
$filter-form-calendar-month-active-bg-color: $signal;
$filter-form-calendar-month-disabled-opacity: 0.4;
$filter-form-badge-bg-color: $signal;
$filter-lead-form-submit-btn-color: $white;
$filter-lead-form-submit-btn-hover-color: $signal;
// scss-docs-end filter

// scss-docs-start contact-banner
$contact-banner-bg-color: $secondary;
// scss-docs-end contact-banner

// scss-docs-start travel-pricing-block
$pricing-block-title-margin-bottom: 3rem;
$pricing-block-info-outer-padding: 0 3rem 0 0;
$pricing-block-info-inner-padding: 2rem 1rem 3rem 1rem;
$pricing-block-info-inner-padding-print: 2rem 1rem 2rem 1rem;
$pricing-block-info-pricing-info-color: $gray-300;
// scss-docs-end travel-pricing-block

// scss-docs-start booking-summary
$booking-summary-primary-color: $white;
$booking-summary-secondary-color: $gray-500;
// scss-docs-end booking-summary

// scss-docs-start star-rating
$star-color: $tertiary;
// scss-docs-end star-rating
