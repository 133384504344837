$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/surprise/';
@import 'styles/helpers/global_variables';

.root {
  @include content-block;

  position: relative;
  margin: 3rem 0;
  padding: 0;

  .imgWrap {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      display: block;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%);
      width: auto !important;
      max-width: none !important;
      height: auto !important;
      min-height: 100%;
      min-width: 100%;
      max-height: none !important;
    }
  }

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    //overflow: hidden;
    color: $white;
    word-break: break-word;
    padding: 1rem;
    user-select: none;
    @include image-block-font;
  }
}
