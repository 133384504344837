$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/surprise/';
@import 'styles/helpers/global_variables';

.wrap {
  text-decoration: none;
  color: $black;

  &:hover {
    color: $body-color;
  }
}

.card {
  height: 100%;
  border: none;
  box-shadow: $shadow-1;
  overflow: hidden;

  &:hover {
    box-shadow: $shadow-3;
    //box-shadow: 0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.20);
  }
}
.body {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 12px;

  .title {
    @include font-uppercase-bold;

    font-size: 1.1rem;
    line-height: 1.52rem;
    height: 50px;
  }

  .date {
    text-transform: none;
    font-size: 0.9rem;
    @include display-linebreak;
  }

  .price {
    font-size: 0.9rem;
    font-style: italic;
    color: $gray-600;
    padding-top: 0.3rem;
  }
}
