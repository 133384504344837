$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/surprise/';
@import 'styles/helpers/global_variables';

.slideWrap {
  position: relative;
  min-width: 100%;

  .slideInner {
    position: relative;
    height: 700px;
    max-height: 75vh;

    .content {
      padding: 0 $header-content-padding-x $header-slider-content-padding-bottom 0;
      max-width: 100%;
      position: absolute;
      right: 0;
      bottom: 0;
      text-align: right;
      color: $white;
      @include readability-text-shadow;

      @include media-breakpoint-up(sm) {
        padding-right: $header-content-padding-x-sm;
      }

      @include media-breakpoint-up(xl) {
        padding-right: $header-content-padding-x-xl;
      }

      .title {
        word-break: break-word;
        margin-bottom: 1.9rem;
        user-select: none;
        line-height: 1;
        @include jumbo-title;
      }

      .text {
        @include jumbo-subtitle;
      }
    }
  }
}
